<template>
  <div class="contcatUs">
    <header>联系我们</header>
    <!-- 背景图 -->
    <div class="img-back">
      <img src="/static/aboutus/contcat.png" alt="" />
    </div>
    <div class="content">
      <!-- 详细信息 -->
      <div class="info">
        <div class="info-title">公司注册名称：</div>
        <div class="info-text">
          北京华奥汽车服务股份有限公司悦心车旧机动车经销分公司
        </div>
        <div class="info-title">悦心车五方天雅旗舰店:</div>
        <div class="info-text">
          北京市朝阳区五方天雅汽车服务园区E4区
        </div>
         <div class="info-title">悦心车安贞店:</div>
        <div class="info-text">
          北京市朝阳区安贞西里五区2、3号楼一层仟村商务大楼
        </div>
        <div class="info-title">营业时间：</div>
        <div class="info-text">9:00 - 17:00</div>
        <div class="info-title">联系电话：</div>
        <div class="info-text">400-997-5955</div>
      </div>
      <!-- 地图 -->
      <div id="aMap"></div>
      <!-- 小程序公众号 -->
      <div class="wraper">
        <div class="list">
          <div class="list-left">
            <div class="list-left-title">扫码关注“ 悦心车Joycar ”公众号</div>
            <div class="list-left-text">了解更多悦心车资讯活动</div>
          </div>
          <img src="/static/aboutus/gzh.png" alt="" />
        </div>
        <div class="list">
          <div class="list-left">
            <div class="list-left-title">扫码关注“ 悦心车 ”官方小程序</div>
            <div class="list-left-text">线上挑选您的心仪车型</div>
          </div>
          <img src="/static/aboutus/xcx.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AMap from 'AMap'
export default {
  data() {
    return {
      map: "",
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let map = ''
      map = new AMap.Map("aMap", {
        center: [116.537992, 39.864251],
        resizeEnable: true,
        zoom: 20,
        expandZoomRange: true,
        // zooms: [18, 20],
        mapStyle: "amap://styles/whitesmoke",
      });
      let marker = ''
      marker = new AMap.Marker({
        icon:
          "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        position: [116.537992, 39.864251],
        offset: new AMap.Pixel(-13, -30),
      });
      marker.setMap(map);
    },
  },
};
</script>

<style lang="less" scoped>
.contcatUs {
  padding-bottom: 100px;
  width: 100%;
  header{
    width: 1200px;
    margin: 0 auto;
    padding-left: 30px;
    font-size: 36px;
    font-weight: 700;
    color: #000;
    height: 120px;
    line-height: 120px;
  }
  .img-back {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    .info {
      margin: 90px 0;
      .info-title {
        font-size: 26px;
        color: #000000;
        font-weight: 600;
      }
      .info-text {
        font-size: 26px;
        color: #000000;
      }
    }

    #aMap {
      width: 1200px;
      height: 620px;
    }

    .wraper {
      width: 100%;
      height: 330px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #e5e5e5;
      align-items: center;
      .list {
        height: 150px;
        display: flex;
        align-items: center;
        .list-left {
          .list-left-title {
            font-size: 24px;
            font-weight: 600;
            color: #000;
          }
          .list-left-text {
            font-size: 24px;
            color: #000;
          }
        }
        img {
          margin-left: 32px;
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}
</style>
